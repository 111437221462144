// Entry point for the build script in your package.json

import {
  Actor,
  CollisionType,
  Color,
  DisplayMode,
  Engine,
  Keys,
  Loader,
  SolverStrategy,
  vec,
  Vector,
} from "excalibur";

document.addEventListener("DOMContentLoaded", () => {
  const gameContainer = document.getElementById("game-container");
  if (gameContainer && gameContainer.dataset.runGame === "true") {
    initializeGame();
  }
});

class Player extends Actor {
  private startedWalkingAt: number | null = null;
  private startedJumpingAt: number | null = null;

  public update(engine: Engine, delta: number) {
    const now = Date.now();

    if (
      (!this.startedWalkingAt || now - this.startedWalkingAt < 50) &&
      engine.input.keyboard.isHeld(Keys.Right)
    ) {
      this.acc = vec(1000, this.acc.y);
      if (!this.startedWalkingAt) {
        this.startedWalkingAt = now;
      }
    } else if (
      (!this.startedWalkingAt || now - this.startedWalkingAt < 50) &&
      engine.input.keyboard.isHeld(Keys.Left)
    ) {
      this.acc = vec(-1000, this.acc.y);
      if (!this.startedWalkingAt) {
        this.startedWalkingAt = now;
      }
    } else {
      this.acc = vec(0, this.acc.y);
      this.vel = vec(this.vel.x * 0.9, this.vel.y);
      this.startedWalkingAt = null;
    }

    if (
      (!this.startedJumpingAt || now - this.startedJumpingAt < 100) &&
      engine.input.keyboard.isHeld(Keys.Up)
    ) {
      this.acc = vec(this.acc.x, engine.physics.gravity.y * -8);
    } else {
      this.acc = vec(this.acc.x, 0);
    }

    super.update(engine, delta);
  }

  public onCollisionStart(self, other, side, contact) {
    if (side === "Bottom") {
      this.startedJumpingAt = null;
    }
  }

  public onCollisionEnd(self, other, side, contact) {
    if (side === "Bottom") {
      this.startedJumpingAt = Date.now();
    }
  }
}

function initializeGame() {
  // Create the Excalibur engine and configure fullscreen mode
  const game = new Engine({
    displayMode: DisplayMode.FillScreen,
    backgroundColor: Color.fromHex("#000000"),
    physics: {
      gravity: new Vector(0, 1000),
      solver: SolverStrategy.Arcade,
    },
  });

  const floorBlock = new Actor({
    x: 700,
    y: game.drawHeight - 100,
    width: 1000,
    height: 100,
    color: Color.White,
  });

  floorBlock.body.collisionType = CollisionType.Fixed;

  const player = new Player({
    x: 300,
    y: 300,
    width: 100,
    height: 100,
    color: Color.Blue,
  });

  player.body.useGravity = true;
  player.body.collisionType = CollisionType.Active;

  game.currentScene.camera.strategy.lockToActor(player);

  game.add(floorBlock);
  game.add(player);

  // Move your game start logic here
  const loader = new Loader();
  game.start(loader).then(() => {
    console.log("Game started");
  });
}
